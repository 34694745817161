<template>
	<div style="width:100%; font-size: 12px;">
		<div style="position:relative; padding:0 10px 10px 30px ">
			<div class="vline"></div>

			<div class="steptitle">
        <div :class="['steppoint flexCenter sp_ok']">
          <i class="el-icon-success" :class="'sp_ok'" style="font-size: 18px;"></i>
        </div>
				流程发起人
<!--        <span style="color:#888">{{ getStDateStr(DataInfo.cdate) }}</span>-->
			</div>
			<div class="spuser" style="margin-top: 10px;">
        <div class="flexStart"  style="width: 100%;">
          <img :src="DataInfo.avatar || temphead" alt="">
          <div class="" style="font-size:14px;color:#909399;margin:0 5px;">{{DataInfo.username }}</div>
          <div class="tagok">发起</div>
          <div class="flex_1 flexEnd"
               style="font-size:12px;color:#909399;">{{DataInfo.cdate?DataInfo.cdate.substr(5,20):''}}
          </div>
        </div>
			</div>


			<div class="nodecontent" v-for="(p, idx) in ProcessList" :key="idx">
				<div :class="['steppoint flexCenter',p.spover>0?'sp_ok':'sp_await']">
          <i v-if="p.spover>0" class="el-icon-success" :class="'sp_ok'" style="font-size: 18px;"></i>
          <i v-if="p.spover==0" class="el-icon-time" style="font-size: 18px;"></i>
        </div>
					<div style="width: 100%;">
						<div class="steptitle">
							<!-- {{ p.teachers.typename }} -->
							<span v-if="p.teachers.typename=='发起人部门领导'">教研室主任审批</span>
							<span v-else-if="p.teachers.typename=='指定审批人'">系领导确认审批</span>
							<span v-else>{{ p.teachers.typename }}</span>
							<span v-if="p.step_type == 'and' && p.sp_users.length > 1" title="会签"
								style="opacity:0.5">🔀</span>
							<span v-if="p.step_type == 'or' && p.sp_users.length > 1" title="或签"
								style="opacity:0.5">🔂</span>
						</div>


						<div class="spuser" v-for="(u, i) in p.sp_users" :key="i"  style="width: 100%;margin-top: 10px;">
              <div class="flexStart"  style="width: 100%;">
                <img :src="u.avatar || temphead" alt="">
                <div class="" style="font-size:14px;color:#909399;margin:0 5px;">{{u.username }}</div>
                <div v-if="p.spover==0&&u.spover==0" class="tagnormal">待审批</div>
                <div v-if="u.spover==1" class="tagok">审批通过</div>
                <div v-if="u.spover==2" class="tagfail">审批驳回</div>
                <div class="flex_1 flexEnd"
                      style="font-size:12px;color:#909399;">{{u.sptime?u.sptime.substr(5,20):''}}
                </div>
              </div>
              <div>
                <img v-if="u.handsign" v-viewer :src="u.handsign" alt=""
                     style="width:auto; height:30px; margin-left: 25px;margin-top: 5px; border: 1px solid #ccc; border-radius: 0;"/>

                <div v-if="u.spword" class="spwordBox" style="margin-top:5px;">
                  <span>{{ u.spword }}</span>
                </div>
              </div>
						</div>
						

					</div>


			</div>
			<div class="nodecontent" v-if="CCList&&CCList.length > 0">
				<div class="steptitle">
          <div :class="['steppoint flexCenter',DataInfo.sp_status>0?'sp_ok':'sp_await']">
            <i v-if="DataInfo.sp_status>0" class="el-icon-success" :class="'sp_ok'" style="font-size: 18px;"></i>
            <i v-if="DataInfo.sp_status==0" class="el-icon-time" style="font-size: 18px;"></i>
          </div>
					结束后-抄送给

				</div>





				<div class="spuser" v-for="(u, i) in CCList" :key="i">
					<img :src="u.avatar || temphead" alt=""> {{ u.username }}
				</div>

			</div>
			<div class="nodecontent" v-else>
				<div class="steptitle">
          <div :class="['steppoint flexCenter',DataInfo.sp_status>0?'sp_ok':'sp_await']">
            <i v-if="DataInfo.sp_status>0" class="el-icon-success" :class="'sp_ok'" style="font-size: 18px;"></i>
            <i v-if="DataInfo.sp_status==0" class="el-icon-time" style="font-size: 18px;"></i>
          </div>
					审批结束
				</div>
				

			</div>

		</div>



		<deptuser module="zichan" :type="nextProcess.sp_type" :nowProcess="nextProcess" v-if="DeptUserDialog"
			v-on:getUser="getUser" :mult="true">
		</deptuser>


	</div>
</template>

<script>
import deptuser from "./deptuser.vue"
export default {
	props: ["process_code", "table_name", "form_id"],
	components: {
		deptuser
	},
	data() {
		return {
			temphead: "//school.mmteck.cn/static/temp.png",
			DataInfo: {},
			ProcessList: [],
			CCList: [],
			icansp: false,
			DeptUserDialog: false,
			checkedUsers: []

		}
	},
	mounted() {
		this.getInfo()

	},

	methods: {
		getStDateStr(d) {
			return this.getsDateStr(d)
		},
		getInfo() {
			this.$http.post("/api/"+this.table_name+"_one", { id: this.form_id }).then(res => {
				if (res.data.process_json) {
					res.data.process_json = JSON.parse(res.data.process_json)
				} else {
					res.data.process_json = []
				}
				this.DataInfo = res.data
				console.log('this.DataInfo')
				console.log(this.DataInfo)
				this.CCList = res.data.cc
				this.getProcessView()
				this.checkIcanSP()
				this.getNextStep()
			})
		},
		getProcessView() {
			this.$http.post("/api/get_sp_process_listinfo", {
				process_code: this.process_code,
				table_name: this.table_name,
				form_id: this.form_id
			}).then(res => {
        if(res.data.process&&res.data.process.length>0){
          for (let item of res.data.process) {
            if (item.teachers.typename == "审批人自选下级审批人") {
              item.teachers.typename = "审批人自选"
            }
          }
          res.data.process.map(p=>{
            let count = 0
            p.spover = 0
            if(p.sp_users&&p.sp_users.length>0){
              p.sp_users.map(u=>{
                if(u.spover>0){
                  p.spover = 1
                  if( p.step_type == 'and'){
                    count+=1
                  }
                }
              })
              console.log(140,count,p.sp_users.length)

              if(count==p.sp_users.length){
                p.spover = 1
              }
            }
          })
          this.ProcessList = res.data.process
        }
        this.CCList = res.data.cc
				console.log(this.CCList)
			})
		},
		checkIcanSP() {
			this.$http.post("/api/get_i_can_sp", {
				form_id: this.form_id,
				table_name: this.table_name
			}).then(res => {
				this.icansp = res.data.icansp
				this.$$parent(this, "icansp", res.data.icansp)
				// this.triggerEvent("sendData", {
				// 	type: "checkSpAuth",
				// 	data: res.data.icansp
				// });


			})
		},
		getNextStep() {
			this.$http.post("/api/get_next_step", {
				form_id: this.form_id,
				table_name: this.table_name
			}).then(res => {
				if (res.data.next) {
					this.nextProcess = res.data.next
					console.log('this.nextProcess')
					console.log(this.nextProcess)
					let isNeedChoose = false;
					let isNeedSptype = false


					if (this.nextProcess.sp_type == "yuxuan" || this.nextProcess.sp_type == "notsure") {
						this.isNeedChoose = true
						//this.triggerEvent("sendData", {type:"isNeedChoose",data:true});
					}
					if (this.nextProcess.step_type == "notsure") {
						this.nextProcess.step_type = "and"
					}

				}


				let processSteps = this.DataInfo.process_json
				for (let step of processSteps) {
					step.spover = 0;
					if (step.sp_users) {
						if (step.step_type == "or") {
							for (let u of step.sp_users) {
								if (u.spover) {
									step.spover = 1;
								}
							}
						} else {
							let allok = 0;
							for (let u of step.sp_users) {
								if (u.spover) {
									allok++
								}
							}
							if (allok == step.sp_users.length && step.sp_users.length > 0) {
								step.spover = 1;
							}
						}

					}


				}
				this.processSteps = processSteps
			})
		},
		getUser(e) {
			if (e.length > 0) {
				this.checkedUsers = e
				this.sendSp(1)
			}
			this.DeptUserDialog = false


		},
		startSp(st,spword) {
			if (this.isNeedChoose) {
				this.DeptUserDialog = true;
				return
			} else {
				this.sendSp(st,spword)
			}
		},

		sendSp(st,spword) {
			this.$http.post("/api/get_sign_pic").then(res => {
				if (res.data && res.data.handsign) {
					let data = { spword: spword, sp_result: st, handsign: res.data.handsign }
					data.form_id = this.DataInfo.id
					if (this.nextProcess) {
						data.step_type = this.nextProcess.step_type
					}
					if (this.checkedUsers && this.checkedUsers.length > 0) {
						data.checkedUsers = this.checkedUsers

					}
					this.$http.post("/api/" + this.table_name + "_form_sp", data).then(res => {
						if (res.data && res.data.code == 200) {
							this.$message.success("审批成功")
							//发送通知接口，调用即可
							this.$http.post("/api/" + this.table_name + "_send_sp_next_msg", data).then(res => {

							})
							this.$$parent(this, "afterCallBack")

						}else {
              this.$message.warning(res.data.msg)

            }
					})
				} else {
					this.$message.error("请先在小程序设置个人签名")
				}
			})

		}
	}
}
</script>


<style scoped="scoped">
.tagok{ display: inline-block; color: #fff; background: #67c23a; font-size: 12px; border-radius: 2px; padding: 0 2px;}
.tagfail{ display: inline-block; color: #fff; background: #fc7b7b; font-size: 12px; border-radius: 2px; padding: 0 2px;}
.tagnormal{ display: inline-block; color: #fff; background: #3bb0f2; font-size: 12px; border-radius: 2px; padding: 0 2px;}
.spwordBox{background-color: #d9ecff;padding: 0 3px;margin-left: 25px;}


.nodecontent {
	position: relative;
}

.spuser {
  width: 100%;
	background-color: #fff;
	margin-bottom: 5px;
	border-radius: 4px;
	padding: 4px 0;
	display: inline-block;
}

.steptitle {
  height: 18px;
  line-height: 18px;
	margin-top: 10px;
	border-radius: 4px;
	position: relative;
}

.steppoint {
	border: 2px solid lightblue;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	position: absolute;
	left: -23px;
	background-color: #fff;
}
.sp_ok{
  border: 0;
  color: #67c23a;
}
.sp_await{
  border: 0;
}
.spuser img {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
}

.steptype {
	background-color: #058e71;
	font-size: 12px;
	padding: 2px 5px;
	margin-left: 10px;
	border-radius: 2px;

	display: inline-block;
}

.vline {
	width: 0px;
	height: 90%;
	left: 15px;
	top: 4%;
	position: absolute;
	border-left: 2px dotted lightblue;
	z-index: 0;
}
</style>
