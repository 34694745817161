<template>
  <div class="" style="width: 100%;height: 100%;">
    <el-dialog title="" :visible.sync="dialogVisible_verify_ck" custom-class="cus_dialog cus_dialogFullHeight"
      width="90%" :close-on-click-modal="false" @closed="onClose">
      <div class="myDialogBox flexBetween">
        <div class="flexCloumnSingleCenter" style="height:100%;width: 40%;">
          <div class="boxShadow flexCloumnSingleCenter" style="height: 60%;width: calc( 100% - 0px );">
            <div class="titleBar flexStart">
              <div class="div flexStart">{{ dialogTitle }}</div>
            </div>
            <div class="flex_1" style="width:100%;overflow-y: auto;margin-bottom: 10px;">
              <el-form class="" :model="EditItem" ref="addzc" label-position="left" label-width="90px"
                style="width:calc( 100% - 10px );">

                <el-form-item label="申请人:">{{EditItem.username}}
                </el-form-item>
                <el-form-item label="所在部门:">{{EditItem.dept_name}}</el-form-item>
                <el-form-item label="申请时间:">{{EditItem.cdate}}</el-form-item>

                <el-form-item label="申领单号:">{{EditItem.sl_num}}</el-form-item>
                <el-form-item label="申领原因:" prop="sl_remark">
                  <el-input type="textarea" :rows="5" :value="EditItem.sl_remark||'-'" size="mini" disabled></el-input>
                </el-form-item>
                <el-form-item label="图片:">
                  <div class="flexStart">
                    <div v-if="EditItem.files&&EditItem.files.length>0" class="updimage"
                      v-for="(f, idx) in EditItem.files" :style="{ backgroundImage: 'url(' + f.src + ')' }"
                      style="position: relative">
                      <img :src="f.src" @click="handlePictureCardPreview(f.src)"
                        style="opacity: 0;width: 100%;height: 100%;cursor:pointer;" />
                    </div>

                    <div v-if="!EditItem.files||EditItem.files.length==0" class="">-</div>
                  </div>
                </el-form-item>

                <el-row v-if="false" style="margin: 10px 0;">
                  <el-form-item label="签收相关图片:">
                    <div class="flexStart">
                      <div class="updimage" v-for="(f, idx) in files" :style="{ backgroundImage: 'url(' + f.src + ')' }"
                        style="position: relative">
                        <img :src="f.src" @click="handlePictureCardPreview(f.src)"
                          style="opacity: 0;width: 100%;height: 100%;" />
                        <div class="flexCenter" @click="delImage(idx)"
                          style="position: absolute;top: -8px;right: -8px;width:20px;height:20px;
                          color: #fff;background-color:#409eff;border-radius: 50%;border: 1px solid #fff;cursor: pointer;">
                          <i class="el-icon-close"></i>
                        </div>
                      </div>
                      <el-upload v-if="files.length < 3" action="" :http-request="uploadOss" :on-success="uploadCover"
                        :on-remove="handleRemove" :show-file-list="false" name="image">
                        <div class="flexCenter uploadBox" style="width: 100px;height: 100px;border:1px dashed #909399;">
                          <i class="el-icon-plus" style="font-size: 26px;"></i>
                        </div>
                      </el-upload>
                    </div>
                  </el-form-item>
                </el-row>
              </el-form>

            </div>
          </div>


          <div class="shadow flexCloumn mt10" style="height:  calc( 40% - 10px);width: 100%;">
            <div class="titleBar flexStart">
              <div class="div flexStart">流程状态</div>
            </div>
            <processview v-if="EditItem.id" ref="processview" :process_code="process_code" table_name="zichan_sl"
              :form_id="EditItem.id" class="flex_1" style="overflow-y: auto;height: calc( 100% - 50px );">
            </processview>
          </div>
        </div>
        <div class="shadow flexCloumnSingleCenter" style="height: 100%;width: calc( 60% - 10px);">
          <el-table v-if="showTable" ref="mySGtable" :data="arr_json" size="mini" height="100%" border
            style="width: 100%;height: 100%;">
            <el-table-column width="62" fixed="left" align="center">
              <template slot="header" slot-scope="scope">
                <div v-if="EditItem.ck_status==1">状态</div>
                <div v-if="!EditItem.ck_status||EditItem.ck_status==0">
                  <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                    @change="handleCheckAll"></el-checkbox>
                </div>
              </template>
              <template slot-scope="scope">

                <div class="" v-if="scope.row.can_ck && (!scope.row.ck_status || (scope.row.ck_status == 0))">
                  <el-checkbox v-model="scope.row.ckd" @change="e=>chooseOne(e,scope.$index)"></el-checkbox>
                </div>
                <div class="" v-if="!scope.row.can_ck && (!scope.row.ck_status || (scope.row.ck_status == 0))">
                  <el-checkbox v-model="ckd_0" disabled></el-checkbox>
                </div>
                <div v-if="scope.row.ck_status==1" style="color:#1289ff;">已出库</div>
              </template>
            </el-table-column>
            <el-table-column type="index" label="序号" width="55" align="center" />
            <el-table-column prop="title" label="耗材名称" min-width="200" :show-overflow-tooltip="true">

            </el-table-column>
            <el-table-column prop="pinpai" label="品牌" width="100" />
            <el-table-column prop="xinghao" label="型号" width="100" />
            <el-table-column prop="typename1" label="分类" width="120" />
            <el-table-column prop="storename" label="所在仓库" width="150" align="center" />
            <el-table-column prop="store_num" label="剩余库存" width="100" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.store_num}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="jine" width="100" :show-overflow-tooltip="true" align="center">
              <template slot="header">
                <span style="color: #F56C6C">申领数量</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{scope.row.slNum}}{{scope.row.jldw}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ckNum" width="150" :show-overflow-tooltip="true" align="center">
              <template slot="header">
                <span style="color: #F56C6C">{{is_ck?'*':''}}出库数量</span>
              </template>
              <template slot-scope="scope">
                <div v-if="!is_ck||EditItem.ck_status==1" style="color: #F56C6C">
                  {{scope.row.ckNum}}{{scope.row.jldw}}</div>
                <div v-if="is_ck" class="flexStart">
                  <el-input class="flex_1 cusinput cgInput" :disabled="scope.row.ck_status==1||!scope.row.ckd"
                    type="number" v-model="scope.row.ckNum" @input="e=>handleCGData(e,scope.row,scope.$index)"
                    @mousewheel.native.prevent :controls="false" size="mini" :min="0"></el-input>
                  <span style="width: 14px;height: auto;margin-left: 4px;display: inline-block;">{{ scope.row.jldw
                    }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="flexEnd">
        <span v-if="EditItem.sp_status==1&&EditItem.ck_status==0&&is_ck"
          style="white-space: nowrap;margin-left: 20px;">出库意见：</span>

        <el-input v-if="EditItem.sp_status==1&&EditItem.ck_status==0&&is_ck" v-model="EditItem.ck_remark" size="small"
          placeholder="出库备注" style="margin: 0 20px;"></el-input>

        <el-button v-if="EditItem.sp_status==1&&EditItem.ck_status==0&&is_ck" type="primary" @click="chuku"
          size="small">出库</el-button>


        <el-button type="primary" @click="onClose" plain size="small">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import processview from "../../../com/processviewmini.vue"

export default {
  components: {
    processview,
  },
  data() {
    return {
      //--审批流程用----
      process_code: "hcsl",
      process_id:0,
      //--审批流程用----
      disabled: false,
      files: [],
      arr_json: [],
      userinfo:{},
      is_ck:false,
      EditItem:{},
      ckd_0:false,
      ckd_1:true,
      showTable:false,
      isIndeterminate:false,
      checkAll: false,

    }
  },
  props: ['dialogVisible_verify_ck', 'dialogTitle', 'EditItem1'],
  mounted() {
    // console.log(174, this.EditItem)
    // this.arr_json =  this.EditItem.hc_list
    this.handleEditItem()

    this.getCreateUser()
  },
  methods: {
    //--审批流程用----
    isChooseuser(e) {//设置是否需要选人
      this.needChoose = e
    },
    chooseUser(e) {//设置选择的人
      this.choosedUser = e
    },
    getProcessID() {
      this.$http.post("/api/form_process", {
        process_code: this.process_code
      }).then(res => {
        this.process_id = res.data.id

      })
    },
    //--审批流程用----
    handleEditItem(){
      let _this = this
      let flag = false
      let ckList = [],arr = []
      let tchRoles = this.$store.state.teacher.sys_roles ?? []
      let tsCk = tchRoles.filter(r => r.role_code == 'ts_ck').length > 0
      this.$http.post('/api/zc_hc_sl_form',{id:this.EditItem1.id}).then(res => {
        if(res.data){
          let resData = res.data
          if (resData.hc_list) {
            resData.hc_list && resData.hc_list.map(h => {
              h.ckNum = h.ckNum?h.ckNum:h.slNum
              h.ckd = false
              h.can_ck = false
              if (!h.ck_status || h.ck_status == 0) {
                console.log(158158,h.id)
                _this.check_ck(h.id).then(b => {
                  if (b) {
                    flag = true
                  }
                  h.can_ck = b

                  if (!flag && resData.sl_type == 2 && tsCk) {
                    flag = true
                    h.can_ck = true
                  }
                })
              }else if(h.ck_status==1){
                ckList.push(h)
              }
            })
            if(ckList&&ckList.length>0){
              arr = [ckList[0]]
              ckList.map((a,idx)=>{
                let namestr = arr.map((n) => n.ck_username).join(',')
                let namearr = namestr.split(',')
                let index = namearr.indexOf(a.ck_username)
                if(index>-1){
                  if(idx>0) arr[index].title+=`,${a.title}`
                }else {
                  arr.push(a)
                }
              })
              console.log(118,arr)
            }
          }
          if (resData.files){
            resData.files = JSON.parse(resData.files)
          }
          if (resData.qs_json) {
            resData.qs_json = JSON.parse(resData.qs_json)
          }
          if(resData.cdate){
            resData.cdate = resData.cdate.substr(0, 16)
          }
          setTimeout(() => {
            _this.EditItem = resData
            _this.arr_json =  resData.hc_list
            _this.is_ck = flag
            _this.ckList = arr
            _this.showTable = true
          }, 500)
          this.getProcessID()
        }
      })
    },
    check_ck(id) {
      return new Promise(resolve => {
        this.$http.post("/api/is_ck_admin", {
          id: id,
        }).then(res => {
          resolve(res.data.boole)
        })
      })

    },
    getCreateUser() {

      this.$http.post("/api/get_teacher_info").then(res => {
        this.userinfo = res.data

      })
    },
    handleCGData(e,row,idx) {
      console.log('handleCGData', e,)
      console.log('handleCGData', e,row.slNum)
      if (Number(e) > Number(row.slNum)) {
        this.$message.warning('出库数量不能超过申领数量~')
        this.arr_json[idx].ckNum = Number(row.slNum)
        return
      }else {
        this.arr_json[idx].ckNum = e
      }
    },
    handleRemark() {
      console.log(this.arr_json)
    },
    uploadCover1(e, b, c, index) {
      // console.log(1082,e,b,c,index)
      let _this = this
      this.arr_json[index].img = e.src
      this.arr_json = [..._this.arr_json]
      _this.$nextTick(() => {
        _this.$forceUpdate()
      })
      // this.files.push({fileName: e.fileName, src: e.src, name: e.fileName, url: e.src})
    },
    delImage1(index) {
      let _this = this
      this.arr_json[index].img = ''
      this.arr_json = [..._this.arr_json]
      _this.$nextTick(() => {
        _this.$forceUpdate()
      })
    },
    uploadCover(e) {
      this.files.push({ fileName: e.fileName, src: e.src, name: e.fileName, url: e.src })
    },
    delImage(idx) {
      this.files.splice(idx, 1)
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.files = fileList
    },

    handlePictureCardPreview(src) {
      console.log(this.$parent)
      this.$$parent(this, 'handlePictureCardPreview', src)
    },
    onClose(){
      this.$$parent(this,'dialogVisible_verify_ck',false)
    },
    chooseOne(e,idx) {
      this.arr_json[idx].ckd = e
      let checkedCount = this.arr_json.filter(a => a.ckd && !a.ck_status).length
      let count = this.arr_json.filter(a => !a.ck_status && a.can_ck).length
      this.checkAll = checkedCount === count;
      this.isIndeterminate = checkedCount > 0 && checkedCount < count;
      console.log(305,this.arr_json[idx].ckd)
    },
    chuku() {
      let _this = this
      let flag = 1
      let flag1 = 0
      let params = {files_ck: ''}
      params = JSON.parse(JSON.stringify(_this.EditItem))
      let ids = this.arr_json.map((a) => a.ckd).join(',')
      if (ids.indexOf('true') < 0) {
        _this.$message.warning("请先选择至少一条耗材");
        return;
      } else {
        this.arr_json.map(a => {
          a.slNum = Number(a.slNum)
          a.ckNum = Number(a.ckNum)
          console.log(353,a.ck_status,a.ckd,a.slNum,a.ckNum  )
          if ((!a.ck_status || a.ck_status == 0) && a.ckd) {
            console.log(1)
            flag1 = 1
          }
          if ((!a.ck_status || a.ck_status == 0) && a.ckd && (!a.ckNum||a.ckNum <= 0)) {
            console.log(2)
            _this.$message.warning(`请填写${a.title}的出库数量且不能小于0~`)
            flag = 0
            return
          }
          if ((!a.ck_status||a.ck_status == 0) && a.ckd && (a.ckNum > a.slNum)) {
            console.log(5)
            _this.$message.warning(`${a.title}的出库数量不能超过申领数量~`)
            flag = 0
            return
          }
          if ((!a.ck_status||a.ck_status == 0) && a.ckd && (a.ckNum > a.store_num)) {
            console.log(4)
            _this.$message.warning(`${a.title}的出库数量不能超过库存数量~`)
            flag = 0
            return
          }

        })
        params.files = JSON.stringify(params.files)
        params.files_ck = JSON.stringify(_this.files)

        setTimeout(() => {
          if (flag && flag1) {

            this.$confirm('是否确认出库?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.getSignUrl(params)
            })
          }
        }, 50)
      }

    },
    getSignUrl(data) {
      let hc_list = []
      this.$http.post("/api/get_sign_pic").then(res => {
        if (res.data && res.data.handsign) {
          hc_list = this.arr_json
          hc_list.map(a => {
            if(a.ckd){
              a.signUrl = res.data.handsign
              a.ck_username = this.userinfo.username
              a.ck_remark = this.EditItem.ck_remark
            }else {
              a.signUrl = ''
              a.ck_username = ''
              a.ck_remark = ''
            }
          })
          data.hc_list = hc_list
          this.saveData(data)
        } else {
          this.$message.error("您未设置手写签名，请先前往小程序设置签名")
        }
      })

    },
    saveData(DataInfo) {
      let _this = this
      //--审批流程用----
      DataInfo.process_id = this.process_id
      //--审批流程用----
      console.log(DataInfo,'$$$')
      this.$http.post("/api/zc_hc_sl_check", DataInfo).then(res => {
        if (res.data.id) {
          this.$message.success("已出库!")
          this.onClose()
          _this.$parent.$refs.SXchukuTable.getList()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleCheckAll(val) {
      this.arr_json.map(a => a.can_ck && !a.ck_status && (a.ckd = val))
      this.isIndeterminate = false
    }
  }
}
</script>


